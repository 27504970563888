var inlet = require('aem-libs/src/initInlet.js').initInlet(
  'AemChat',
  /* global WEBPACK_INLET_VERSION */ WEBPACK_INLET_VERSION
)
var isIphoneOrIpad = require('./services/isIphoneOrIpad').isIphoneOrIpad
var containScriptWithUrl = require('./services/containScriptWithUrl').containScriptWithUrl
var wgService = require('aem-libs-angularjs/src/services/wg.service').wgService()

inlet.element.getElementsByClassName('external-widget-loader-loading')[0].style.display = 'none'
inlet.element.style.minHeight = '0px'
wgService.init('AemChat', null, inlet.element)
var disabledRootPages = wgService.getParams().disabledRootPages || []
var disabledExactPages = wgService.getParams().disabledExactPages || []

if(window.location.search.indexOf('disableChat=true') !== -1) {
  // uloží vypnutí / zapnutí chatu z query param do session storage
  window.sessionStorage.setItem('disableChat', true)
}
if(window.location.search.indexOf('disableChat=false') !== -1) {
    // uloží vypnutí / zapnutí chatu z query param do session storage
  window.sessionStorage.setItem('disableChat', false)
}

if(isChatEnabled()) { // pokud není Chat vypnutý (URL param -> session storage)
  if (isIphoneOrIpad() && !wgService.getParams().forceGwc) {
    loadAppleBusinessChat() //jedná se o Apple zařízení, není vynucen GWC parametrem, spouští se BussinessChat
  } else {
    if (isUrlEnabled()) {
      setTimeout(loadGwChat, 1000)
    }
  }
}

function isChatEnabled () {
  //na základě sesion storage povoluje načtení chatu
  return !JSON.parse(window.sessionStorage.getItem("disableChat"))
}

function isUrlEnabled () {
  var currentPath = window.location.pathname
  if (disabledExactPages.indexOf(currentPath) > -1) {
    return false
  }
  for (var idx = 0; idx < disabledRootPages.length; idx++) {
    if (currentPath.indexOf(disabledRootPages[idx]) !== -1) {
      return false
    }
  }
  return true
}

function loadGwChat () {
  var gwChatScriptUrl = wgService.getParams().gwChatScriptUrl || 'https://www.csas.cz/onlinechat/server/resources/js/chat_eml.js'
  var gwChatScriptElement = document.createElement('script')
  gwChatScriptElement.src = gwChatScriptUrl
  window.document.querySelector('head').appendChild(gwChatScriptElement)
}

function loadAppleBusinessChat () {
  var appleBusinessChatScriptUrl = wgService.getParams().appleBusinessChatScriptUrl || 'https://static.cdn-apple.com/businesschat/start-chat-button/2/index.js'
  var appleBusinessId = wgService.getParams().appleBusinessId || 'bec7a2ef-6b3c-4de3-8658-83aa89d47b8d'
  var abcTemplate = require('./templates/abc.tpl.html')
  abcTemplate = abcTemplate.replace('{{appleBusinessId}}', appleBusinessId)
  window.document.querySelector('body').insertAdjacentHTML('afterbegin', abcTemplate)
  if (!containScriptWithUrl(appleBusinessChatScriptUrl)) {
    var appleBusinessChatScriptElement = document.createElement('script')
    appleBusinessChatScriptElement.src = appleBusinessChatScriptUrl
    window.document.querySelector('head').appendChild(appleBusinessChatScriptElement)
  }
  waitForAppleBusinessChat()
}

function waitForAppleBusinessChat () {
  if (typeof window.appleBusinessChat !== 'undefined') {
    window.appleBusinessChat.refresh()
  } else {
    setTimeout(waitForAppleBusinessChat, 250)
  }
}
