function getWidgetElement (appName) {
  return document.querySelector('[widget="' + appName + '"]:not([initialized])') || document.querySelector('[data-widget="' + appName + '"]:not([initialized])')
}

function decamelize (camelizedName) {
  return camelizedName.split(/([A-Z]{1}[^A-Z]*)/g).filter(function (s) {
    return s !== ''
  }).join('-').toLowerCase()
}

function getBasePath (element) {
  if (element) {
    var src = element.getAttribute('src') || element.getAttribute('data-src')
  }

  if (src) {
    return src + '/' + decamelize(element.getAttribute('widget') || element.getAttribute('data-widget')) + '/'
  } else {
    // Webpack development public path
    var locationParts = window.location.href.split('/')
    return locationParts[0] + '//' + locationParts[2] + '/'
  }
}

function initInlet (appName, appVersion) {
  console.info('Inlet ' + appName + ' version: ' + appVersion + ' loaded')
  var element = getWidgetElement(appName)

  if (element) {
    element.setAttribute('initialized', 'true')
    element.setAttribute('data-version', appVersion)
  }
  var basePath = getBasePath(element)
  // Webpack public path
  if (__webpack_public_path__ !== undefined) { // eslint-disable-line
    __webpack_public_path__ = basePath // eslint-disable-line
  }
  return {
    element: element,
    basePath: basePath
  }
}

module.exports = {
  initInlet: initInlet
}
