wgService.$inject = []

function wgService () {
  var _appName = null
  var _inletScope = null
  var _widgetElement = null

  function init (appName, inletScope, widgetElement) {
    _appName = appName
    _inletScope = inletScope
    _widgetElement = widgetElement
  }

  function getAppName () {
    return _appName
  }

  function getToken () {
    return _inletScope.getToken()
  }

  function refreshToken () {
    return _inletScope.refreshToken()
  }

  function getRouteData () {
    return _inletScope.getRouteData()
  }

  function getParams () {
    if (_inletScope) {
      return _inletScope.getParam()
    } else {
      return _widgetElement ? JSON.parse(_widgetElement.getAttribute('params') || _widgetElement.getAttribute('data-params')) : null
    }
  }

  function getPageUrl (objectName) {
    return _inletScope.getPageUrl(objectName)
  }

  function getContentUrl (objectName) {
    return _inletScope.getContentUrl(objectName)
  }

  function setParams (params) {
    if (Object.keys(params).length > 0) {
      _inletScope.updateRoute(params)
    }
  }

  function createState (name) {
    _inletScope.state.create(name, {})
  }

  function getState (name) {
    return _inletScope.state.get(name)
  }

  function setState (name, index) {
    _inletScope.state.set(name, index)
  }

  return {
    init: init,
    getAppName: getAppName,
    getToken: getToken,
    refreshToken: refreshToken,
    getRouteData: getRouteData,
    getParams: getParams,
    getPageUrl: getPageUrl,
    getContentUrl: getContentUrl,
    setParams: setParams,
    createState: createState,
    getState: getState,
    setState: setState
  }
}

exports.wgService = wgService
